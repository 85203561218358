<script setup>
import { ref, watch, onMounted, onBeforeUnmount, defineProps, defineEmits, defineExpose } from 'vue';

const props = defineProps({
  title: String,
  items: Array,
  modelValue: String,
  isActive: Boolean
});

const emit = defineEmits(['update:modelValue']);


const menu = ref(null);
const isOpen = ref(false);
const selectedOption = ref(props.modelValue || props.title);
var hasSelectedOption = ref(false);


watch(selectedOption, (newValue) => {
  emit('update:modelValue', newValue);
});

const handleClickOutside = (event) => {
  if (menu.value && !menu.value.contains(event.target)) {
    closeDropdown();
  }
};

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
};

const selectOption = (option) => {
  selectedOption.value = option;
  isOpen.value = false;
  hasSelectedOption=true;
  emit('update:modelValue', option);
};

const closeDropdown = () => {
  isOpen.value = false;
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

defineExpose({
  selectOption,
  closeDropdown
});

</script>

<template>
  <div ref="menu" class="menu-item" :class="{'inactive':!isActive}" @click="toggleOpen">
    <a :class="{ 'no-selection': !hasSelectedOption }">{{ selectedOption }}</a>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#F4899C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
      <path d="M6 9l6 6 6-6"></path>
    </svg>
    <transition name="fade" appear>
      <div class="sub-menu" v-if="isOpen">
        <div v-for="(item, i) in items" :key="i" class="menu-item">
          <a @click="selectOption(item.title)">{{ item.title }}</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.menu-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:5px;
  padding-left:5px;
  background-color: #fff;
}

.menu-item a {
  color: #484A4B;
  font-family: 'montserrat';
  font-weight: normal;
  background-color: #fff;
  padding: 0px 25px 0px 5px;
  min-width: 75px;
  width: 270px;
  max-width: 300px;
  height: 42px;
  text-overflow: ellipsis;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide the overflowing text */
  display: flex;
  align-items: center;
  text-align: left;
  vertical-align: middle;
}
.menu-item a.no-selection {
  opacity: 0.7; /* Opacity for no selection */
}

.menu-item a.has-selection {
  opacity: 1; /* Opacity for selection */
}
svg {
  margin-left: -26px;
  padding-top: 3px;
}

.menu-item a:hover {
  background-color: #e6e3dd;
}

.sub-menu {
  position: absolute;
  background-color: #FFF;
  top: calc(100%);
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  border-radius: 0px 0px 0px 0px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 2;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sub-menu::-webkit-scrollbar {
    display: none;
  }

.sub-menu .menu-item a{
  height:fit-content;
  text-overflow: none;
  white-space:initial;
  overflow:auto;
  left:2px;
  padding-right:15px;
  padding-left:13px;
  text-align: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: all .2s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.menu-item.inactive {
  background-color: #484A4B;
  opacity: 0.5;
  pointer-events: none;
}
.sub-menu .menu-item:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px; /* Adjust thickness as needed */
    background-color: #e0dcd1;
    position: absolute;
    bottom: 0;
    
  }

@media (min-width: 768px) and (max-width: 1023px) {
  .menu-item a {
    width:85px;
    font-size: 80%;
  }
}

@media (max-width: 767px) {
  *{
    color:#484A4B;
  }
  .menu-item {
    width: 292px;
    padding-left:0px;
  }
  .menu-item a{
    width:292px;
    margin-bottom: 3px;
    margin-right: 6px;
    text-align: center;
    padding-left:10px;
    background-color: #fff;
  }
  .menu-item a:hover{
    background-color: #d3d3d3;
  }
  .sub-menu{
    top:40px;
    width: 101%;
    margin-left:1px;
    background-color: #fff;
    left:50.25%;
  }
  .sub-menu .menu-item{
    text-align: center;
    align-content: space-around;
    width:100%;
    margin-top:7px;
    margin-top:5px;
  }
  .sub-menu .menu-item:not(:last-child)::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px; /* Adjust thickness as needed */
    background-color: #0081A7;
    position: absolute;
    bottom: 0;
  }
  svg{
    margin-left:-30px;
  }
}
</style>
