<script setup>
  import { ref, onMounted, onBeforeUnmount, watch, nextTick } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useRoute, useRouter } from 'vue-router'
  import { useToolStore } from '@/stores/toolStore'
  import { refineCountryName } from './inputclean'

  import apiClient from '@/apiClient.js'
  import cardGrid from './cardGrid.vue'
  import filtersList from './filtersList.vue'
  import mobileCardDetails from './mobileCardDetails.vue'

  const store = useToolStore()
  const { selectedCard } = storeToRefs(store)
  const route = useRoute()
  const router = useRouter()

  const showDialog = ref(false)
  const photographers = ref([])
  const savedScrollPosition = ref(0)
  const filtersListRef = ref(null)
  const dialogBoxRef = ref(null)
  const scrollBoxRef = ref(null)

  onMounted(async () => {
    await fetchPhotographers()
    if (route.query.submissionComplete === 'true') {
      showDialog.value = true
      document.addEventListener('click', handleClickOutside)
    }
    window.addEventListener('resize', pushToDesktop)
  })

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside)
    window.removeEventListener('resize', pushToDesktop)
  })

  watch(showDialog, (newVal) => {
    if (newVal) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }
  })

  watch(selectedCard, (newVal) =>{
    if (newVal){
      store.savedScrollPosition=savedScrollPosition.value
    }
    else {
      scrollBoxRef.value.scrollTop = store.savedScrollPosition
    }
  })

  const pushToDesktop = () =>{
    if(window.innerWidth>768){
      router.push('/searchTool')
      window.removeEventListener('resize', pushToDesktop)
    }
  }
  const handleScroll = () => {
    if (!selectedCard.value) {
      savedScrollPosition.value = scrollBoxRef.value.scrollTop
    }
  }

  const handleClickOutside = (event) => {
    if (dialogBoxRef.value && !dialogBoxRef.value.contains(event.target)) {
      closeDialog()
    }
  }

  const closeDialog = () => {
    showDialog.value = false
    router.replace({ query: null })
  }

  const fetchPhotographers = async () => {
    try {
      const response = await apiClient.get('/photographers')
      photographers.value = response.data.map(photographer => {
        try {
          let pastCities = []
          if (photographer.photog_pastcities && typeof photographer.photog_pastcities === 'string') {
            pastCities = photographer.photog_pastcities.split(';').map(item => item.trim())
          }
          
          const additionalCountries = pastCities
            .filter(city => city.includes(','))
            .map(city => {
              const parts = city.split(',')
              return parts.length > 1 ? parts[parts.length - 1].trim() : null
            })
            .filter(country => country && country.length > 2)
          
          const usaPastCities = pastCities.some(city => {
            const parts = city.split(',')
            return parts.length > 1 && parts[parts.length - 1].trim().length === 2
          })

          let countries = [photographer.photog_country, ...additionalCountries].filter(Boolean)
          if (!photographer.photog_country || photographer.photog_country.trim() === '') {
            countries = ['USA', ...countries]
          }
          if (usaPastCities) {
            countries = [...new Set([...countries,'USA'])];
          }

          countries = countries.map(refineCountryName);

          let currentCity = photographer.photog_currentcity || ''
          if (photographer.photog_country && photographer.photog_country.trim().toUpperCase() !== 'USA') {
            currentCity = `${currentCity} ${photographer.photog_country}`.trim()
          }
          
          let dutyStation = photographer.photog_dutystation
          if (dutyStation !== undefined && dutyStation !== null) {
            dutyStation = dutyStation.trim()
          }

          return {
            ...photographer,
            photog_specialties: photographer.photog_specialties
              ? photographer.photog_specialties.split(',').map(item => item.trim())
              : [],
            photog_pastcities: pastCities,
            photog_updated: photographer.photog_updated
              ? photographer.photog_updated.split('T')[0]
              : '',
            photog_country: [...new Set(countries)],
            photog_currentcity: currentCity,
            photog_dutystation: dutyStation
          }
        } catch (error) {
          console.error(`Error processing photographer ${photographer.photog_index}:`, error)
          return null
        }
      }).filter(photographer => photographer !== null)

      fetchCards()
    } catch (error) {
      console.error('Error fetching photographers:', error)
      store.allphotographers.value = []
    }
  }

  const fetchCards = () => {
    store.updateAllPhotographers(photographers.value)
    store.updateFilteredPhotogaphers(photographers.value.sort((a,b) => a.photog_business.localeCompare(b.photog_business)))
    applyFilters()
  }

  const isStateMatch = (photographer) => {
    const filterStateUpper = store.filterSt?.toUpperCase() || ''
    const isCurrentCityMatch = photographer.photog_currentcity?.toUpperCase().includes(filterStateUpper) || false
    const isPastCityMatch = photographer.photog_pastcities
      ? photographer.photog_pastcities.some(pastCity => {
          const [, country] = pastCity.split(',')
          return country?.trim().toUpperCase().includes(filterStateUpper) || false
        })
      : false

    return { isCurrentCityMatch, isPastCityMatch }        
  }

  const applyFilters = () => {
    store.filteredPhotographers = store.allPhotographers.filter(photographer => {
      const isCurrentCountryMatch = !store.filterC ||
                            store.filterC === 'Country' ||
                            (photographer.photog_country.length > 0 && 
                             store.filterC.toLowerCase() === photographer.photog_country[0].toLowerCase());

      const isPastCountryMatch = !store.filterC ||
                            store.filterC === 'Country' ||
                            (photographer.photog_country.length > 1 && 
                             photographer.photog_country.slice(1).some(country => 
                               store.filterC.toLowerCase() === country.toLowerCase()));

      const isCurrentStateMatch = !store.filterSt ||
                          store.filterSt === 'State/Region' ||
                          (photographer.photog_currentcity.includes(',') &&
                            photographer.photog_currentcity.split(',')[1].trim().toUpperCase() === store.filterSt.toUpperCase());

      const isPastStateMatch = !store.filterSt ||
                          store.filterSt === 'State/Region' ||
                          photographer.photog_pastcities.some(pastCity =>
                            pastCity.includes(',') &&
                            pastCity.split(',')[1].trim().toUpperCase() === store.filterSt.toUpperCase()
                          );

      const isBaseMatch = !store.filterB || 
                          store.filterB.toLowerCase() === photographer.photog_dutystation.toLowerCase()||
                          store.filterB === 'Base';

      const isSpecialtyMatch = !store.filterSp || 
                              photographer.photog_specialties.some(photog_specialties =>
                                photog_specialties.toLowerCase().includes(store.filterSp.toLowerCase())
                              )||
                              store.filterSp === 'Specialty';

      const isNameMatch = !store.filterN || 
                          photographer.photog_name.toLowerCase().includes(store.filterN.toLowerCase());

      const isCountryMatch = isCurrentCountryMatch || isPastCountryMatch;
      const isStateMatch = isCurrentStateMatch || isPastStateMatch;

      if (store.filterC === 'Country' && store.filterSt === 'State/Region' || !store.filterC && !store.filterSt || (store.filterB && store.filterB !== 'Current Base')) {
        photographer.photog_currentLoc = 0;
      } else if ((photographer.photog_country.length > 0 && store.filterC.toLowerCase() === photographer.photog_country[0].toLowerCase())
                  || (photographer.photog_currentcity.includes(',') && photographer.photog_currentcity.split(',')[1].trim().toUpperCase() === store.filterSt.toUpperCase())) {
          photographer.photog_currentLoc = 1;
      } else {
          photographer.photog_currentLoc = 2;
      }

      return isCountryMatch && isStateMatch && isBaseMatch && isSpecialtyMatch && isNameMatch;
    });

    if (store.filterSt !== 'State/Region' && store.filterSt !== '') {
      const photographersCopy = [...store.filteredPhotographers];
      photographersCopy.sort((a, b) => {
        const aMatches = isStateMatch(a);
        const bMatches = isStateMatch(b);

        if (aMatches.isCurrentCityMatch && !bMatches.isCurrentCityMatch) {
          return -1;
        }
        if (!aMatches.isCurrentCityMatch && bMatches.isCurrentCityMatch) {
          return 1;
        }
        if (aMatches.isPastCityMatch && !bMatches.isPastCityMatch) {
          return -1;
        }
        if (!aMatches.isPastCityMatch && bMatches.isPastCityMatch) {
          return 1;
        }
        return 0;
      });
      store.filteredPhotographers = photographersCopy.sort((a,b) => a.photog_business.localeCompare(b.photog_business));
    }

    store.keyChange();
  }

  const resetDropdowns = async () => {
    store.triggerResetFilters();
    applyFilters();
    store.resetDropdownValues();
  }

  const showCardDetails = (card) => {
    store.selectedCard = card
    scrollBoxRef.value.scrollTo(0, 0)
    if (scrollBoxRef.value) {
      savedScrollPosition.value = scrollBoxRef.value.scrollTop
      scrollBoxRef.value.scrollTop = 0
    }
  }

  const closeCard = () => {
    store.selectedCard = null
    nextTick(() => {
      scrollBoxRef.value.scrollTop = store.savedScrollPosition
    })
  }

</script>

<template>
  <div class="scroll-box" ref="scrollBoxRef" @scroll="handleScroll">
  <div class="container" v-if="!selectedCard">
    <h1>FIND A MILSPOUSE PHOTOGRAPHER</h1>
    <h3 class="forms-link">
        <a><router-link to="/formsTool">CLICK HERE TO REGISTER YOUR BUSINESS</router-link></a>
    </h3>
    <filtersList 
        class="filterBox" 
        name="filtersList" 
        ref="filtersListRef" 
        :photographers="photographers" 
        @filters-changed="applyFilters"
      />
    <button class="reset-button" @click="resetDropdowns">RESET</button>
  </div>
  <div class="box">
    <cardGrid class ="cardGrid" v-if="!selectedCard" @card-selected="showCardDetails"/>
    <mobileCardDetails class = "cardDetails" v-if="selectedCard" :card="selectedCard" @close-card = "closeCard" />
  </div>
  <div v-if="showDialog" class="dialog-overlay">
      <div class="dialog-box">
        <p class="close-x" @click="closeDialog">X</p>
        <p>Your information has been<br> added to the database!</p>
      </div>
    </div>
</div>
</template>
    
<style scoped>
  *{
    padding:0;
    margin:0;
  }
  .scroll-box{
    overflow-y:scroll;
    scrollbar-color: #ffffff #a9d2e7;
    scrollbar-width: thin;
    height:fit-content;
    height:100vh;
  }
  .container{
    top:10vh;
    left:10%;
    height:370px;
    background-color: #ffffff;
    width:80%;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .box{
    height:fit-content;
  }
  h1{
    top:25px;
    font-size: 30px;
    color: #0081a7;
    font-weight:normal;
    }
    .forms-link{
    margin-top:25px;
    }
    a{
    color: #484a4B;
    font-family: 'montserrat';
    font-size: 75%;
    font-weight: normal;
    }
    .filterBox{
    display:flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
  
    }
    .reset-button {
      top: 45px;
      position: relative;
      background-color: #CDDFCD;
      color: #0081A7;
      font-family: 'amaranth', sans-serif;
      font-weight: normal;
      font-size: larger;
      border: none;
      padding: 5px 80px;
      cursor: pointer;
      text-align: center;
      z-index:1;
    }
    
    .cardDetails{
      position: relative;
      z-index: 4;
    }
    .card-details-container {
      position: absolute;
      top:-336px;
      left:5vw;
      margin-top:75px;
      height:80vh;
    }
    .dialog-overlay{
      position: absolute;
      left:5vw;
      top:100px;
      width:90vw;
      background-color: #f4899c;
      color:#fff;
      font-family: 'montserrat';
      font-size:28px;
      z-index: 10;
      padding:30px 0px;
      text-align: center;
    }
    .dialog-box p{
      text-align: center;
      margin-top:10px;
      font-size: 24px;
    }
    .close-x{
      position: absolute;
      right:10px;
      top:-35px;
      font-size: 20px;
    }
  </style>
  