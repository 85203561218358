export function abbreviateBaseName (fullName){
  const abbreviations = {
    'Fort': 'Ft.',
    'Ft ':'Ft. ',
    'Air Force Base': 'AFB',
    'Naval Station': 'NS',
    'Naval Air Station': 'NAS',
    'Marine Corps Base': 'MCB',
    'Marine Corps Air Station': 'MCAS',
    'Coast Guard Station': 'CGS',
    'Joint Base': 'JB',
    'Air National Guard Base': 'ANGB',
    'Space Force Base': 'SFB',
    'Army Air Field': 'AAF',
    'Army Garrison':'USAG',
    'US Army Garrison':'USAG',
    'United States Army Garrison':'USAG'
  };
  
  const jointBases = [
    'Andrews',
    'Anacostia-Bolling',
    'Charleston',
    'Elmendorf-Richardson',
    'Langley-Eustis',
    'Lewis-McChord',
    'McGuire-Dix-Lakehurst',
    'Myer-Henderson Hall',
    'Pearl Harbor-Hickam',
    'San Antonio'
  ];
  
  for (const baseName of jointBases) {
    if (fullName.includes(baseName)) {
      return `JB ${baseName}`;
    }
  }
  
  let abbreviatedName = fullName;
  if (fullName.startsWith('Ft.')){
    return fullName;
  }
  for (const [full, abbr] of Object.entries(abbreviations)) {
    if (fullName.startsWith(full)) {
      abbreviatedName = fullName.replace(full, abbr);
      break;
    }
    else if (fullName.endsWith(full)) {
      abbreviatedName = fullName.replace(full, abbr);
      const parts = abbreviatedName.split(' ');
      parts.push(parts.shift());
      abbreviatedName = parts.join(' ');
      break;
    }
  }
  
  return abbreviatedName;
}

export function refineCountryName (countryName){
  const countryMap = {
    'de': 'Germany',
    'jp': 'Japan',
    'it': 'Italy',
    'uk': 'United Kingdom',
    'tr': 'Turkey',
    'es': 'Spain',
    'bh': 'Bahrain',
    'qa': 'Qatar',
    'kw': 'Kuwait',
    'sa': 'Saudi Arabia',
    'uae': 'United Arab Emirates',
    'jo': 'Jordan',
    'rok': 'Republic of Korea',
    'korea': 'Republic of Korea',
    'south korea': 'Republic of Korea',
    'republic of korea': 'Republic of Korea',
    'united kingdom': 'United Kingdom',
    'england' : 'United Kingdom',
    'United States':'USA',
    'US':'USA'  
  }

  const normalizedInput = countryName.trim()
                                    .toLowerCase();
  
  if(countryMap[normalizedInput]){
    return countryMap[normalizedInput];
  }
  return countryName;
}

export function urlCheckTrim(urlFields) {
  const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?([?].*)?$/i;
  const updatedUrlFields = new Array(6).fill('');

  for (let i = 0; i < urlFields.length; i++) {
    let field = urlFields[i];
    let fieldElement = document.getElementById(field);
    if (fieldElement && fieldElement.value) {
      // First, check if the URL is valid
      if (!urlRegex.test(fieldElement.value)) {
        alert(`Please enter a valid URL for ${field}.`);
        return false;
      }
      // Strip the PHP query part
      const index = fieldElement.value.indexOf('?');
      console.log('index:', index, fieldElement.value)
      if (index !== -1) {
       
        updatedUrlFields[i] = fieldElement.value.substring(0, index);
        console.log(updatedUrlFields[i])
      } else {
        updatedUrlFields[i] = fieldElement.value;
      }
    }
  }
  console.log(updatedUrlFields)
  return updatedUrlFields;
}