<script setup>
  import { ref, computed, onMounted, onBeforeUnmount, defineProps, defineEmits } from 'vue';
  import DropDownDetails from './DropDownDetails.vue';

  const props = defineProps(['card']);
  const emit = defineEmits(['close-card']);

  const carddetails = ref(null);
  const clicknum = ref(0);
  const screenWidth = ref(window.innerWidth);

  const pastCities = computed(() => {
    if (Array.isArray(props.card.photog_pastcities)) {
      return props.card.photog_pastcities.map(city => ({ title: city }));
    }
    return [{ title: 'None' }];
  });

  const specialties = computed(() => {
    if (Array.isArray(props.card.photog_specialties)) {
      return props.card.photog_specialties.map(specialty => ({ title: specialty }));
    }
    return [];
  });

  const dynamicImage = computed(() => {
    if (screenWidth.value < 768) {
      return props.card.photog_logo_url || 'https://tools.erinthompsonphoto.com/public/Images/Logos/DefaultLogo.png';
    } else {
      return props.card.photog_headshot_url || 'https://tools.erinthompsonphoto.com/public/Images/Headshots/DefaultHeadshot.png';
    }
  });

  const dynamicBranch = computed(() => {
    const branch = props.card.photog_branch;
    let imagepath = '';
    switch (branch) {
      case 'Air Force':
        imagepath = require("@/assets/USAF.png");
        break;
      case 'Army':
        imagepath = require("@/assets/USA.png");
        break;
      case 'Navy':
        imagepath = require("@/assets/USN.png");
        break;
      case 'Marines':
        imagepath = require("@/assets/usmc.png");
        break;
      case 'Coast Guard':
        imagepath = require("@/assets/USCG.png");
        break;
      case 'Space Force':
        imagepath = require("@/assets/USSF.png");
        break;
      default:
        imagepath = require("@/assets/USAF.png");
        console.warn(`Unknown branch: ${branch}`);
    }
    return imagepath;
  });

  const formatUrl = (url) => {
    if (typeof url !== 'string') {
      return '';
    }
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'https://' + url;
    } else if (url.startsWith('http://')) {
      url = url.replace('http://', 'https://');
    }
    return url;
  };

  const goToWebsite = () => {
    const formattedUrl = formatUrl(props.card.photog_website);
    window.open(formattedUrl, '_blank');
  };

  const updateScreenWidth = () => {
    screenWidth.value = window.innerWidth;
  };

  const closeCard = () => {
    emit('close-card');
  };

  const handleClickOutside = (event) => {
    if (props.card && carddetails.value && !carddetails.value.contains(event.target) && clicknum.value > 0) {
      if (event.target.tagName.toLowerCase() !== 'a') {
        closeCard();
      }
    }
    clicknum.value++;
  };

  onMounted(() => {
    document.addEventListener('click', handleClickOutside);
    window.addEventListener('resize', updateScreenWidth);
  });

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
    window.removeEventListener('resize', updateScreenWidth);
  });
</script>

<template>
  <div class="scrolling">
  <div class="card-details-container" ref="carddetails" v-if="card">
    <div class="left-section">  
      <img :src="dynamicImage" alt="Dynamic Image" class="headshot" />
      <p class="updatedDate">Last Updated: {{ card.photog_updated }}</p>
    </div>
    <div class="full-right-section">
        <h1 class="business">{{ card.photog_business }}</h1>
        <h2 class="email">{{ card.photog_email }}</h2>
        <div class="split-right-section">
          <div class="group">
            <p>Name</p>
            <p>{{ card.photog_name }}</p>
          </div>
          <div class="group">
            <p>Current Location</p>
            <p>{{ card.photog_currentcity }}</p>
          </div>
          <div class="group">
            <p>Previous Locations</p>
            <DropDownDetails class="Dropdown" ref="locationDropdown" title="Previous Locations"  :items="pastCities" />
          </div>
          <div class="group">
            <p>Specialties</p> 
            <DropDownDetails class="Dropdown" ref="specialtyDropdown" title="Specialties" :items="specialties" />
          </div>
          <div class="group">
            <div class="socials"> 
              <a v-if="card.photog_insta" :href="card.photog_insta" target="_blank">
                <img src="@/assets/insta.png" alt="Instagram" class="social-icon" />
              </a>
              <a v-if="card.photog_fb" :href="card.photog_fb" target="_blank">
                <img src="@/assets/fb.png" alt="Facebook" class="social-icon" />
              </a>
              <a v-if="card.photog_yt" :href="card.photog_yt" target="_blank">
                <img src="@/assets/yt.png" alt="YouTube" class="social-icon" />
              </a>
              <a v-if="card.photog_tt" :href="card.photog_tt" target="_blank">
                <img src="@/assets/tiktok.png" alt="tiktok" class="social-icon" />
              </a>
              <a v-if="card.photog_pin" :href="card.photog_pin" target="_blank">
                <img src="@/assets/pinterest.png" alt="pinterest" class="social-icon" />
              </a>
            </div> 
            <button v-if="card.photog_website" class="website-button" @click="goToWebsite">GO TO WEBSITE</button>
          </div>              
        </div>
      </div>
    <div class="branch-icon">
      <img :src="dynamicBranch" alt="BranchImage" class="branch"/>
    </div>
    <div class="close-icon" @click="closeCard">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#484a4b" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </div> 
  </div>
  </div>
</template>

<style scoped>

  *{
    margin:0;
    padding:0;
    box-sizing: border-box;
  }
  .scrolling{
    position: absolute;
    display:flex;
    justify-content: center;
    overflow-y: scroll;
    top:120px;
    height:calc(100vh - 120px);
    scrollbar-width: none;
  }
  .card-details-container{
    position: relative;
    width:90%;
    max-width: 1026px;
    background-color: #faf7f0;
    border-radius: 0.5rem;
    height:400px;
    display:grid;
    grid-template-columns: 3fr 12fr 1fr 1fr;    
  }
  
  .close-icon {
    position: relative;
    float:right;
    cursor: pointer;
    margin-top:5px;
    margin-right:5px;
  }

  .branch-icon{
     max-width: 50px;
    max-height:50px;
    margin: 20px 5px;
  }

  .branch{
    max-width: 55px;
    max-height:55px;

  }

  .card-details{
    display:flex;
    padding:15px;
  }
  

  .left-section{
    padding:0;
    align-self: center;
    height:100%;
  }

  .headshot{
    height:38vh;
    max-height: 335px;
    min-height:300px;
    max-width:350px;
    margin: 20px 0px 10px 30px;
    max-width: 270px;
    clip-path: inset(10px round 5px);
    object-fit: cover;
    object-position: center 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .updatedDate{
    font-weight:300;
    font-size: 14px;
    font-family: 'montserrat';
    padding-right:10px;
    text-align: center;
    margin-left:23px;
  }

  .full-right-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height:335px;
  padding-left: 10px;
  padding-top:20px;
  padding-bottom: 5px;
  text-align: left;
}
  
  .full-right-section h1{
    color:#0081a7;
    font-size:34px;
    font-weight:normal;
    height: 56px;
  }
  .full-right-section h2{
    color: #484a4b;
    font-family: 'montserrat';
    font-weight:400;
    font-size: 125%;
  }

  .split-right-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;;
  }
  
  .group{
    display:grid;
    height:40px;
    grid-template-columns: 4fr 5fr;
    color: #484a4b;
    font-family: 'montserrat';
    font-weight:400;
    font-size: 125%;
    margin:0px 5px 10px 5px;
    align-content: center;
    justify-items: start;
  }
  .group p{
    margin:5px;
    align-self: center
  }
  .details{
      float:right;
      width:100%;
      color: #484a4b;
      font-family: 'montserrat';
      font-weight:400;
      font-size: 125%;
  }

  .details p{
    margin-top: 1.5vh;
    background-color: #BBDEF0;
    text-align: left;
    padding:5px;
  }

  .locationDropdown{
    margin-top:1.5vh;
    width:100%;
    background-color: #BBDEF0;
  }

  .specialtyDropdown{
    margin-top:1.5vh;
    width:100%;
    background-color: #BBDEF0;
  }

  button{
    position: relative;
    background-color: #F4899C;
    font-family: 'montserrat';
    color:#fff;
    font-size: 75%;
    border:none;
    justify-content: center;
    height:50px;
    padding-top:8px;
    padding-bottom:8px;
    top:15px;
    width:40%;
    left:30%;
  }

  .website-button{
    transition: .3;
    width:200px;
    background-color: #F4899C;
    cursor: pointer;
    margin-right:160px;
  }
  .website-button:hover{
    background-color: #df7387;
  }

  .socials {
    top: 25px;
    width: 150px;
  }

  .socials a{
    padding-right:15px;
  }

  .socials img{
    max-height:25px;
  }
  .email{
    margin:5px 0px 10px 5px;
  }

  @media (min-width: 768px) and (max-width: 1120px) {
    .card-details-container {
      height:400px;
      width: 730px;
      z-index: 1;
      grid-template-columns: 150px 450px 100px 25px;
    }
    .split-right-section{
      display:grid;
      grid-template-rows: 40px 60px 60px 40px 60px;
    }

    .group{
      height:35px;
    }
    .left-section{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .headshot{
      height:auto;
      max-width:100%;
      clip-path: inset(5px);
      display: block;
      margin:10px 0px 0px 10px;
      object-fit: cover;

    }
    .details button{
      font-size: 60%;
      left: auto;
        }
    h1.business{
      font-size:150%;
    }
    .details{
      width:50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .details p{
      width:200px
    }
    .locationDropdown{
    margin-top:1.5vh;
    width:200px;
    background-color: #BBDEF0;
  }

  .specialtyDropdown{
    margin-top:1.5vh;
    width:200px;
    background-color: #BBDEF0;
  }
  .socials{
    top:55px;
  }
  }
  @media (max-width: 767px) {
    .card-details-container{
      display:flex;
      flex-direction: column;
      height:70vh;
      margin-bottom: 200px;
    }
    .left-section{
      height:160px;
    }
    .headshot{
      height:100px;
    }
    .updatedDate{
      font-size:12px;
    }
    .full-right-section{
      margin:10px;
      height:65%;
    }
    .full-right-section h1{
      font-size: 30px;
      margin-bottom: 25px;
    }
    .full-right-section h2{
      font-size: 16px;
    }
    .split-right-section{
      height:100%;
    }
    .branch-icon{
      justify-self: center;
      left:35vw;
    }
    .website-button{ 
      width: 150px;
      align-self: center;
      left:30px;
    }
  }

</style>