<script setup>
  import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useRoute, useRouter } from 'vue-router'
  import apiClient from '@/apiClient.js'
  import cardGrid from './cardGrid.vue'
  import filtersList from './filtersList.vue'
  import cardDetails from './cardDetails.vue'
  import { useToolStore } from '@/stores/toolStore'
  import { refineCountryName } from './inputclean'

  const store = useToolStore()
  const { selectedCard } = storeToRefs(store)
  const photographers = ref([])
  const route = useRoute()
  const router = useRouter()
  const showDialog = ref(false)
  const savedScrollPosition = ref(0)
  const filtersListRef = ref(null)
  const cardGridRef = ref(null)
  const dialogBoxRef = ref(null)

  onMounted(async () => {
    store.isMobile ? router.push('/mobileSearchTool') : null
    await fetchPhotographers()
    if (route.query.submissionComplete === 'true') {
      showDialog.value = true
      document.addEventListener('click', handleClickOutside)
    }
    window.addEventListener('resize', pushToMobile)
  })

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside)
    window.removeEventListener('resize', pushToMobile)
  })

  watch(showDialog, (newVal) => {
    if (newVal) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }
  })

  const pushToMobile = () =>{
    if(window.innerWidth<768){
      router.push('/mobileSearchTool')
      window.removeEventListener('resize', pushToMobile)
    }
  }

  const saveScrollPosition = (position) => {
    savedScrollPosition.value = position
  }

  const handleClickOutside = (event) => {
    if (dialogBoxRef.value && !dialogBoxRef.value.contains(event.target)) {
      closeDialog()
    }
  }

  const closeDialog = () => {
    showDialog.value = false
    router.replace({ query: null })
  }

  const fetchPhotographers = async () => {
    try {
      const response = await apiClient.get('/photographers')
      photographers.value = response.data.map(photographer => {
        try {
          let pastCities = [];
          if (photographer.photog_pastcities && typeof photographer.photog_pastcities === 'string') {
            let cleanedString = photographer.photog_pastcities.replace(/;$/, '');
            pastCities = cleanedString.split(';').map(item => item.trim());
          }
          let additionalCountries = pastCities
            .filter(city => city.includes(','))
            .map(city => {
              const parts = city.split(',');
              return parts.length > 1 ? parts[parts.length - 1].trim() : null;
            })
            .filter(country => country && country.length > 2);
          
          const usaPastCities = pastCities.some(city => {
            const parts = city.split(',');
            return parts.length > 1 && parts[parts.length - 1].trim().length === 2;
          });

          let countries = [photographer.photog_country, ...additionalCountries].filter(Boolean);
          if (!photographer.photog_country || photographer.photog_country.trim() === '') {
            countries = ['USA', ...countries];
          }
          if (usaPastCities) {
            countries = [...new Set([...countries,'USA'])];
          }

          countries = countries.map(refineCountryName);

          let currentCity = photographer.photog_currentcity || '';
          if (photographer.photog_country && photographer.photog_country.trim().toUpperCase() !== 'USA') {
            const refinedCountry = refineCountryName(photographer.photog_country);
            currentCity = `${currentCity} ${refinedCountry}`.trim();
          }
          
          let dutyStation = photographer.photog_dutystation;
          if (dutyStation !== undefined && dutyStation !== null) {
            dutyStation = dutyStation.trim();
          }

          return {
            ...photographer,
            photog_specialties: photographer.photog_specialties
              ? photographer.photog_specialties.split(',').map(item => item.trim())
              : [],
            photog_pastcities: pastCities,
            photog_updated: photographer.photog_updated
              ? photographer.photog_updated.split('T')[0]
              : '',
            photog_country: [...new Set(countries)],
            photog_currentcity: currentCity,
            photog_dutystation: dutyStation,
            photog_currentLoc: 0
          }
        } catch (error) {
          console.error(`Error processing photographer ${photographer.photog_index}:`, error)
          return null
        }
      }).filter(photographer => photographer !== null)

      fetchCards()
    } catch (error) {
      console.error('Error fetching photographers:', error)
      store.allPhotographers = []
    }
  }

  const fetchCards = () => {
    store.updateAllPhotographers(photographers.value)
    store.updateFilteredPhotogaphers(photographers.value.sort((a,b) => a.photog_business.localeCompare(b.photog_business)))
    applyFilters();
  }

  const isStateMatch = (photographer) => {
    const filterStateUpper = store.filterSt?.toUpperCase() || '';
    const isCurrentCityMatch = photographer.photog_currentcity?.toUpperCase().includes(filterStateUpper) || false;
    const isPastCityMatch = photographer.photog_pastcities
      ? photographer.photog_pastcities.some(pastCity => {
          const [, country] = pastCity.split(',');
          return country?.trim().toUpperCase().includes(filterStateUpper) || false;
        })
      : false;

    return { isCurrentCityMatch, isPastCityMatch };        
  }

  const applyFilters = () => {
    store.filteredPhotographers = store.allPhotographers.filter(photographer => {
      const isCurrentCountryMatch = !store.filterC ||
                            store.filterC === 'Country' ||
                            (photographer.photog_country.length > 0 && 
                             store.filterC.toLowerCase() === photographer.photog_country[0].toLowerCase());

      const isPastCountryMatch = !store.filterC ||
                            store.filterC === 'Country' ||
                            (photographer.photog_country.length > 1 && 
                             photographer.photog_country.slice(1).some(country => 
                               store.filterC.toLowerCase() === country.toLowerCase()));

      const isCurrentStateMatch = !store.filterSt ||
                          store.filterSt === 'State/Region' ||
                          (photographer.photog_currentcity.includes(',') &&
                            photographer.photog_currentcity.split(',')[1].trim().toUpperCase() === store.filterSt.toUpperCase());

      const isPastStateMatch = !store.filterSt ||
                          store.filterSt === 'State/Region' ||
                          photographer.photog_pastcities.some(pastCity =>
                            pastCity.includes(',') &&
                            pastCity.split(',')[1].trim().toUpperCase() === store.filterSt.toUpperCase()
                          );

      const isBaseMatch = !store.filterB || 
                          store.filterB.toLowerCase() === photographer.photog_dutystation.toLowerCase()||
                          store.filterB === 'Base';

      const isSpecialtyMatch = !store.filterSp || 
                              photographer.photog_specialties.some(photog_specialties =>
                                photog_specialties.toLowerCase().includes(store.filterSp.toLowerCase())
                              )||
                              store.filterSp === 'Specialty';

      const isNameMatch = !store.filterN || 
                          photographer.photog_name.toLowerCase().includes(store.filterN.toLowerCase());

      const isCountryMatch = isCurrentCountryMatch || isPastCountryMatch;
      const isStateMatch = isCurrentStateMatch || isPastStateMatch;

      if (store.filterC === 'Country' && store.filterSt === 'State/Region' || !store.filterC && !store.filterSt || (store.filterB && store.filterB !== 'Current Base')) {
        photographer.photog_currentLoc = 0;
      } else if ((photographer.photog_country.length > 0 && store.filterC.toLowerCase() === photographer.photog_country[0].toLowerCase())
                  || (photographer.photog_currentcity.includes(',') && photographer.photog_currentcity.split(',')[1].trim().toUpperCase() === store.filterSt.toUpperCase())) {
          photographer.photog_currentLoc = 1;
      } else {
          photographer.photog_currentLoc = 2;
      }

      return isCountryMatch && isStateMatch && isBaseMatch && isSpecialtyMatch && isNameMatch;
    });

    if (store.filterSt !== 'State/Region' && store.filterSt !== '') {
      const photographersCopy = [...store.filteredPhotographers];
      photographersCopy.sort((a, b) => {
        const aMatches = isStateMatch(a);
        const bMatches = isStateMatch(b);

        if (aMatches.isCurrentCityMatch && !bMatches.isCurrentCityMatch) {
          return -1;
        }
        if (!aMatches.isCurrentCityMatch && bMatches.isCurrentCityMatch) {
          return 1;
        }
        if (aMatches.isPastCityMatch && !bMatches.isPastCityMatch) {
          return -1;
        }
        if (!aMatches.isPastCityMatch && bMatches.isPastCityMatch) {
          return 1;
        }
        return 0;
      });
      store.filteredPhotographers = photographersCopy.sort((a,b) => a.photog_business.localeCompare(b.photog_business));
    }
    store.keyChange();
  }
  
  const resetDropdowns = () => {
    store.triggerResetFilters();
    applyFilters();
    store.resetDropdownValues();
  }

  const showCardDetails = (card) => {
    store.selectedCard = card
    store.freezeAllDropdowns();
  }

  const closeCard = () => {
    store.clearSelectedCard()
  }
</script>

<template>
  <div class="container">
    <div class="search-box" v-if="!selectedCard" ref="searchBox">
      <h2 class="heading">
        FIND A MILSPOUSE PHOTOGRAPHER
      </h2>
      <h3 class="forms-link">
        <a><router-link to="/formsTool">CLICK HERE TO REGISTER YOUR BUSINESS</router-link></a>
      </h3>
      <filtersList 
        class="filterBox" 
        name="filtersList" 
        ref="filtersListRef" 
        :photographers="store.allPhotographers" 
        @filters-changed="applyFilters"
      />
      <button class="reset-button" @click="resetDropdowns">RESET</button>
    </div>
    
    <cardGrid 
      class="cardGrid" 
      ref="cardGridRef" 
      v-if="!selectedCard" 
      @card-selected="showCardDetails"  
      @scroll-position-changed="saveScrollPosition"
    /> 
    <cardDetails 
      class="cardDetails" 
      v-if="selectedCard" 
      :card="selectedCard" 
      @close-card="closeCard" 
    />
    <div v-if="showDialog" class="dialog-overlay">
      <div class="dialog-box" ref="dialogBoxRef">
        <p class="close-x" @click="closeDialog">X</p>
        <p>Your information has been<br> added to the database!</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .container {
    height: 100vh;
    display: grid;
    justify-items: center;
    overflow-y: auto;
    overflow:hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .search-box {
    width: 80vw; 
    top:100px;
    height: 200px;
    background-color: white;
    position: relative;
    position:fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
  }
  .filterBox{
    height:0px;
    margin-top: -10px;
    z-index: 4;
  }
  .cardGrid{
    position: absolute;
    width: 100vw;
    justify-items: center;
    margin-top:10px;
  }

  h2 {
    color: #0081A7;
    font-weight: lighter;
    font-size: xx-large;
    margin-bottom: -5px;
    transition: color 0.3s; /* Smooth transition for hover effect */
    padding-top:5px;
  }

  .forms-link {
    margin-top: 5px;
  }

  .forms-link a {
    padding: 0;
    text-decoration: none;
    font-family: 'montserrat';
    font-weight: 400;
    font-size: small;
    color: #484A4B;
  }

  .forms-link a:hover {
    color: #f4899c; /* Hover color for the router-link */
    transition: .2s;
  }

  .reset-button {
    top:80px;
    position: relative;
    background-color: #BBDEF0;
    color: #0081A7;
    font-family: 'amaranth', sans-serif;
    font-weight: normal;
    font-size: larger;
    border: none;
    padding: 10px 80px;
    cursor: pointer;
    text-align: center;
    z-index:3;
  }

  .reset-button:hover {
    background-color: #CDDFCD;
    transition: .5s;
  }

  .card-details-container {
    position: absolute;
    align-self: center;
    max-height:425px;
    z-index: 5;
  }
  .dialog-box{
    background-color: #f4899c;
    font-size:35px;
    font-family: 'montserrat';
    color:#fff;
    padding:20px;
    border-radius: 5px;
    cursor:default;
    box-shadow: 0 16px 32px rgba(0, 0, 0, .5);
    z-index: 100;
    top:350px;
  }
  .close-x{
    font-size: 15px;
    text-align: right;
    margin-top:-5px;
    cursor:pointer;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    .card-details-container {
      position: absolute;
      z-index:4;
      height:400px;
      max-height: 90vh;
    }
  }
</style>