<script setup>
import { ref, onMounted, onBeforeUnmount, computed, watch, nextTick } from 'vue';
import { useToolStore } from '@/stores/toolStore';

const store = useToolStore();
const gridContainer = ref(null);
const scrollPosition = ref(0);
const photographers = ref([])


const updateScrollPosition = () => {
  if (!store.isMobile && gridContainer.value.scrollTop) {
    scrollPosition.value = gridContainer.value.scrollTop;
  }
};

watch(() => store.cardCloseFlag, () => updateScroll());

const updateScroll = () => {
  if (!store.selectedCard && !store.isMobile) {
    nextTick(() => {
      gridContainer.value.scrollTop = store.savedScrollPosition;
      store.savedScrollPosition = 0;
    });
  }
};


watch(() => store.filteredPhotographers, () => updateGrid());

const selectPhotographer = (photographer) => {
  store.setSelectedCard(photographer, scrollPosition.value);
};

const dynamicHeadshot = computed(() => {
  return (photographer) => {
    return photographer.photog_headshot_url || 'https://tools.erinthompsonphoto.com/public/Images/Headshots/DefaultHeadshot.png';
  };
});

const dynamicLogo = computed(() => {
  return (photographer) => {
    return photographer.photog_logo_url || 'https://tools.erinthompsonphoto.com/public/Images/Logos/DefaultLogo.png';
  };
});

const updateGrid = () => {
  photographers.value = store.filteredPhotographers
}


onMounted(() => {
  gridContainer.value.addEventListener('scroll', updateScrollPosition);
  updateGrid()
  updateScroll()
});

onBeforeUnmount(() => {
  gridContainer.value.removeEventListener('scroll', updateScrollPosition);
});
</script>

<template>
  <div class="scroll-container" ref ="gridContainer">
    <div class="card-grid">
     <div v-for="photographer in photographers" :key="photographer.photog_index" @click="selectPhotographer(photographer)" class="card"
     :class="{
          'current-match': photographer.photog_currentLoc === 1,
          'past-match': photographer.photog_currentLoc === 2
        }">
        <div class="right-side">
          <img :src="dynamicHeadshot(photographer)" alt="Photographer Headshot" class="headshot" loading="lazy"/>
        </div>
        <div class = "left-side">
          <img :src="dynamicLogo(photographer)" alt="Brand Logo" class="thumbnail" />
          <div>
            <p class="card-text">{{ photographer.photog_business }}</p>
            <p class="current-loc">{{ photographer.photog_currentcity }}</p>
          </div>
        </div>  
      </div>
    </div>
  </div>
</template>

<style scoped>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .scroll-container{
    overflow-y: scroll;
    height:calc(100vh - 110px);
    -ms-overflow-style: none;
    scrollbar-width: none;
    top:110px;
    }
  .scroll-container::-webkit-scrollbar {
    display: none;
  }
  .card-grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 330px);
    justify-content: center;
    grid-auto-flow: row dense; 
    grid-auto-rows: auto;
    grid-row-gap: 15px;
    grid-column-gap: 1vw;
    margin: 200px 10px 10px 10px;
    padding-top: 25px;
  }

  .card {
    border: 3px solid transparent;
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    width: 100%;
    max-width: 330px;
    height: auto;
    min-height:215px;
    display: flex;
    flex-direction: row-reverse;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .card.current-match{
    border-color: #0081A7;
  }

  .card.past-match{
    border-color: #F4899C;
  }
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .left-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding-left:15px;
  }

  .right-side {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .thumbnail {
    width: 100%;
    max-width: 60px;
    height: auto;
    padding-top:10px;
  }

  .headshot {
    max-height: 225px;
    width: auto;
    max-width: 100%;
    clip-path: inset(10px round 5px);
    object-fit: cover;
    object-position: center 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    right:5px;
  }

  .card-text {
    font-weight: 400;
    text-align: center;
    color: #0081A7;
    font-size: 125%;
    padding: 5px;
  }

  .current-loc {
    font-size: 85%;
    font-family: 'montserrat';
    margin-top: 5px;
  }
  @media (min-width:767px) and (max-width:1100px){
    .card-grid{
      grid-template-columns: repeat(3, 230px);
    }
    .card{
      max-width: 270px;
    }
  }
  @media (max-width: 767px) {
    .scroll-container {
      top:90px;
      max-height: 475px;
      overflow-y:visible;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    .scroll-container::-webkit-scrollbar {
    display: none;
    }
    
    .card-grid {
    position: relative;
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: repeat(2, 170px);
    grid-auto-flow: row dense; 
    grid-auto-rows: auto;
    grid-row-gap: 2vw;
    grid-column-gap: 2vw;
    padding-top:50px;
    margin: 10px;
    height: auto;
    padding-bottom: 100px;
  }
  .card {
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    width: 100%;
    height: auto;
    min-height:200px;
    display: flex;
    flex-direction: row-reverse;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .right-side{
    width: 190px;
  }
  .card:hover {
    transform: scale(1);
  }
  .left-side{
    padding:0px;
    width:0px;
    height: 100%;
  }
  .thumbnail {
    height:0;
    width: 0;

  }
  .headshot {
    max-height: 200px;
    width: 170px;
    clip-path: inset(10px round 5px);
    object-fit: cover;
    object-position: center 0px; /* Move the image down */
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    left:1px;
  }
  .card-text{
    background-color: #faf7f0;
    position: absolute;
    font-size: 75%;
    width:153px;
    top:3px;
    left:9px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding-left: 10px;
    padding-right: 10px;
  }
  .current-loc{
    opacity: 0;
    height: 0;
    width:0;
  }
}
</style>