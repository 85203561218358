import { createApp } from 'vue'
import { createPinia } from 'pinia';
import App from './App.vue'
import router from './router.js';

window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

createApp(App)
    .use(createPinia())
    .use(router).mount('#app')

